<template>
  <loading />
</template>
<script>
export default {
    computed: {
        jwt: (state) => state.$route.query.jwt,
        refresh_token: (state) => state.$route.query.refresh_token,
        user_uuid: (state) => state.$route.query.user_uuid,
    },
    async mounted() {
        if (this.jwt && this.refresh_token && this.user_uuid) {
            await localStorage.setItem("token", this.jwt);
            await localStorage.setItem("refresh_token", this.refresh_token);
            await localStorage.setItem("user_id", this.user_uuid);

            setTimeout(() => {
                this.$router.push("/welcome/phone");
            }, 2000);
        }
    },
    // https://merlinface.com/redirect-from-subdomain?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2ODI0NzMwNzksImV4cCI6MTY4MjQ3NjY3OSwidXVpZCI6ImUwNjFmOWJlLWRmMzYtMTFlZC05N2U4LTAyNDJhYzEyMDAwOSIsInVzZXJuYW1lIjoiTmV3YmllIiwicm9sZXMiOlsiUk9MRV9OT19QSE9ORSIsIlJPTEVfVVNFUiJdfQ.fhXjWZfqSAJ3ZE8y-TABcHkFw9vlXLKlnM-STEoxPjN7F50pwDnkO86H3XGIV1pc5s6imZLwhkTl0Km9X1zRHYXhp_EZvzzKeodslyXZMLRv_CZO72gaW07xijUDqc5UcjDrGvT8zGIGttcGOISL9Gk5l1TsOrkDF7WaPly7I0fUEAOl0zZsXzZqsR1ZA3k4EGpuHZPmldj69cItGk37dbGnlQ_boHUQCLlBe9MmR9yPphh5KTLcBJnD-Qr8NHrbnEVVIRaT2uZwfLkriAXAD4tA7abD3JPCHBEkJ2iUjj8tX_LuEs88nYxxSIy46OIZKX8J9kYSHwqM8GaoVdmK15dOYB5kw1lnDR0FfFlhp-m6qMAqekC-22lyFvF6rv6Eu8is6K73X0uq1XJxXk9FZAmTJtys3QDA6bHyaugjJJCtQuSD0MeF54XR9TVvuGUQxsnSmQTKWp4D0DzwL8bBaN8_Tfl7-0wJY_2UIs1Uabt-NYe5wGMiaR1F_YLn3yWNwzux2sMXS1oWeBHHD405Dv_89SkSTpmXCNcOMUW-lCoCZqi8EkvayckmpNolDY_056ZtJJG3jNUVJshRWwJ9IpMu76RToorITbNSig-M1_6ej6_dqnAP-gkFydKoDLcdn44Eq06Mv8QFC7ehQUtoBSU6M-M512BRPwjWXRGLrWs&refresh_token=24MeXaYMkqpZ4ZGye6M16eXOlVdh07qL9zBlELMloaR9l3hzt8lU9BRjK74lR4uS&user_uuid=e061f9be-df36-11ed-97e8-0242ac120009
};
</script>
